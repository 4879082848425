import React, { useState, useEffect } from "react"
import "../settings.css"
import FullNameRow from "./fullNameRow"
import SloganRow from "./sloganRow"
import AxiosInstance from "../../../../services/axiosInstance"
import MaillingAddress from "./maillingAddress"
import HumanPhotoRow from "./humanPhotoRow"

const UniqueRows = () => {
  const [loadUniqueData, setLoadUniqueData] = useState(true)
  const [sloganData, setSloganData] = useState(null)
  const [firstNameData, setFirstNameData] = useState(null)
  const [lastNameData, setLastNameData] = useState(null)
  const [mailAddressData, setMailAddressData] = useState(null)

  const setUserDetails = async () => {
    AxiosInstance.get("/settings/general-setting/unique-data").then(response => {
      response.data.uniqueData.map((data, index) => {
        if (data.type === "Slogan") {
          setSloganData(data)
        }
        if (data.type === "FirstName") {
          setFirstNameData(data)
        }
        if (data.type === "LastName") {
          setLastNameData(data)
        }
        if (data.type === "MailingAddress") {
          setMailAddressData(data)
        }
      })
    })
  }

  useEffect(() => {
    if (loadUniqueData) {
      setLoadUniqueData(false)
      setUserDetails()
    }
  })

  return (
    <React.Fragment>
      <FullNameRow firstNameData={firstNameData} lastNameData={lastNameData} />
      <div className="contactDetailsContainer">
        <HumanPhotoRow />
        <div className="overflow-hidden px-3 px-md-0">
          <SloganRow sloganData={sloganData} />
          <MaillingAddress mailAddressData={mailAddressData} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default UniqueRows
