import * as React from "react"

import Settings from "../../components/Settings/ProfilePage/settings"
import PrivateRoute from "../../components/privateRoute"
import ReduxWrapper from "../../redux/reduxWrapper"

const IndexPage = props => {
  return <PrivateRoute component={Settings} location={props.location} />
}

const WrappedPage = props => <ReduxWrapper element={<IndexPage {...props} />} />
export default WrappedPage
