import React, { useState } from "react"
import AxiosInstance from "../../../services/axiosInstance"

const WebSiteWorkRow = ({ settingData }) => {
  const [webSiteWork, setWebSiteWork] = useState(settingData?.value)
  const [id, setId] = useState(settingData?.id)

  const handleWebSiteWork = e => {
    if (webSiteWork === e.target.value) {
      return false
    }

    setWebSiteWork(e.target.value)

    let url = "/settings/general-setting/add"
    if (id) {
      url = `/settings/general-setting/edit/${id}`
    }
    AxiosInstance.post(url, {
      type: "WebsiteWork",
      value: e.target.value,
      privacy: "public",
    })
      .then(response => {
        setId(response.data.id)
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          console.log(error)
        }
      })
  }

  return (
    <div className="row adbook-user-other-info align-items-center py-3 border-bottom">
      <div className="col-md-4 col-sm-8 col text-md-end text-start">
        <label>
          <i className="bi bi-link-45deg" /> Website (Work)
        </label>
      </div>
      <div className="col-md-2 col-sm-4 col-sm-2 col text-end text-sm-center privacy-col">
        <i className="bi bi-globe" />
        {/* <Privacy /> */}
      </div>
      <div className="col-md-4 inputbox">
        <input
          type="url"
          className="form-control"
          placeholder="https://"
          defaultValue={webSiteWork}
          onBlur={handleWebSiteWork}
        />
      </div>
    </div>
  )
}

export default WebSiteWorkRow
